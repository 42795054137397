<template>
  <div class="container_footer_news_room">
    <div class="container_footer_news_room__col"></div>
    <div class="container_footer_news_room__text text-center">
      <label>
        &copy; {{ currentYear }} <a href="https://dogeniuson.com/">dogeniuson.com</a> | Do Genius On, LLC. All rights reserved
      </label>
    </div>
    <div class="container_footer_news_room__social_media text-center">
      <a href="https://www.facebook.com/Do-Genius-On-LLC-110413997900290" target="_blank">
        <vue-fontawesome icon="facebook-square" size="2"></vue-fontawesome>
      </a>
      <a href="https://www.linkedin.com/company/do-genius-on" target="_blank">
        <vue-fontawesome icon="linkedin" size="2"></vue-fontawesome>
      </a>
      <!-- <a href="#" target="_blank">
        <vue-fontawesome icon="youtube" size="2"></vue-fontawesome>
      </a> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterNewsRoom',
  data() {
    return {
      currentYear: null,
    };
  },
  mounted() {
    let year = new Date();
    this.currentYear = year.getFullYear();
  }
};
</script>
