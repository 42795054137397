<template>
  <div class="container_login">
    <form @submit.prevent="sendform">
      <router-link :to="{ name: 'home' }">
        <div class="container_login__logo mb-3">
          <div class="container_login__logo--img">
            <img src="@/assets/img/do-genius-on-white.svg" class="img-fluid">
          </div>
          <div class="container_login__logo--text">
            <h5>
              BUILDING <br>
              TECHNOLOGY <br>
              FOR TECHNOLOGY <br>
              BUILDERS
            </h5>
          </div>
        </div>
      </router-link>
      <div class="mb-3">
        <input type="email" placeholder="Email" class="form-control"
              :class="{'is-invalid': $v.form.email.$error}"
              v-model.trim="$v.form.email.$model">

        <span role="alert" class="invalid-feedback" v-if="$v.form.email.$error">
          <strong v-if="!$v.form.email.required">The Email is required</strong>
          <strong v-if="!$v.form.email.email">Invalid Email format</strong>
          <strong v-if="!$v.form.email.maxLength">
            The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
          </strong>
        </span>
      </div>
      <div class="container_login__form--btn mb-3">
          <button type="submit" class="btn">SEND RESET PASSWORD LINK</button>
      </div>
      <div class="container_login__form--link">
        <router-link :to="{ name: 'login' }">
          Back to login
        </router-link>
      </div>
    </form>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators';

import Footer from '@/components/FooterNewsRoom.vue';

export default {
  name: 'Login',
  components: {
    Footer,
  },
  data() {
    return {
      form: {
        email: null
      },
      load: false,
    };
  },
  beforeCreate() {
    if (this.$session.exists()) {
      this.$router.push({ name: 'dashboard' });
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
        maxLength: maxLength(60),
      },
    },
  },
  methods: {
    sendform() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post('password/reset', this.form).then((response) => {
          if (response.status == 200) {
            this.$notify({ group: 'app', text: response.body.message, type: 'success', duration: 100000 });
          }
          this.load = false;
        }, (error) => {
          if (error.status === 500) {
            this.$notify({ group: 'app', text: 'An error occurred while trying to reset password. Try again.', type: 'error' });
          }
          this.load = false;
        })
      }
    },
  },
};
</script>
